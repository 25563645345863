<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-0'"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :color="color" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          
        </div>
        <ul class="navbar-nav justify-content-end">

          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body lh-1"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>

          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color ? color : 'text-body', showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="material-icons cursor-pointer notranslate"> settings </i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >

            <li class="mb-2">
              <a class="dropdown-item border-radius-md" href="javascript:;">
                <div class="py-1 d-flex">
                  <div class="my-auto">
                    <i
                    class="avatar avatar-sm bg-gradient-success me-3"
                      
                      alt="user image"
                    ><ion-icon name="person-outline"></ion-icon></i>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-1 text-sm font-weight-normal">
                      <span class="font-weight-bold">Welcome</span> {{ this.email  }}
                    </h6>
                    
                  </div>
                </div>
              </a>
            </li>
              <!--
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i
                      class="avatar avatar-sm bg-gradient-dark me-3"
                        
                        alt="user image"
                      ><ion-icon name="create-outline"></ion-icon></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Alterar</span> Profile
                      </h6>
                      
                    </div>
                  </div>
                </a>
              </li>
              -->
              <li class="mb-2" >
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex" v-on:click="logout">
                    <div class="my-auto">
                      <i
                        class="avatar avatar-sm bg-gradient-danger me-3"
                        alt="logo spotify"
                      ><ion-icon name="exit-outline"></ion-icon></i>

                    </div>
                    <div class="d-flex flex-column justify-content-center" >
                      <h6 class="mb-1 text-sm font-weight-normal" >
                        <span class="font-weight-bold" >Logout</span>
                      </h6>
               
                    </div>
                  </div>
                </a>
              </li>
              
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapState } from "vuex";
import {http} from "../../http/index.js"

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      email: null,
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    

    toggleSidebar() {
      this.navbarMinimize();
    },
    logout() {
      var token = localStorage.getItem("token")
      if (token == null) {
        this.$router.push('/login')
        // Retorna aqui para evitar que o restante do código seja executado
      }

      var payload = {
        token: token,
      }; 

      http
        .post("/api/v1/dashboard/logout/", payload)
        .then((response) => {
          localStorage.clear(); // Limpa o localStorage após uma resposta bem-sucedida
          this.$router.push('/login') // Redireciona para a rota de login após uma resposta bem-sucedida
        })
        .catch((error) => {
          this.$router.push('/login')
        });
    },
  },
  components: {
    Breadcrumbs,
    
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute"]),
  },
  updated() {
    this.email = localStorage.getItem("email_user");
  },
};
</script>
