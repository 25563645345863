<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4 pb-12">

            <div
              class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
              <h6 class="col text-white text-capitalize ps-3">Reports</h6>
            </div>       
         
              <Loading message="Loading your reports" :loading = "loading"/>

              <!-- Token not found -->
              <div id="not_found" class= "col ps-3 w-25 alert alert-danger" v-if="(returnAPI == [] && showMessage == true)">
                  Sorry, token not found.
              </div>

              <div  id= "report" else-if="Array.isArray(returnAPI)">
             
                <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-danger" v-if = "returnAPI.length == 0 && showMessage == true"> There are no reports. </div>
                <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-success" v-if = "returnAPI.length == 1  && showMessage == true"> There's {{ returnAPI.length }} report found. </div>
                <div id = "total" role="alert" class= "col ps-3 w-25 alert alert-success" v-if = "returnAPI.length > 1 && showMessage == true">There're {{ returnAPI.length }} reports found. </div>

                <ReportTable :returnAPI="returnAPI"/>
              
              </div>

            </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ReportTable from "@/views/components/ReportTable.vue";
import Loading from "@/views/components/Loading.vue";


import {http} from "../http/index";

export default {

  name: "Reports",
  
  components : {
    ReportTable, Loading
  },

  data() {
        
        return {
          token:  localStorage.getItem("token"),
          returnAPI: [],
          loading: false,
          showMessage : false
        };

    },
    mounted(){  

      this.searchReports(this.token)

    },

  methods: {

    async searchReports (token){

      var payload = {
        token: this.token
      }

      const response = await http.post("/api/v1/dashboard/reports-by-user/", payload)
      .then((response) => {
          this.returnAPI = response.data
          this.showMessage = true;    
      })
      .catch(error => {
          this.returnAPI = false
          this.loading = false;
      }).finally(() => {
        this.loading = false;
      })

      this.showMessage = true;
      this.loading = false;
    }
  }
};
</script>

<style scoped>

#total{
  font-size: 16px;
  color: white;
  text-align: center;
}

#loading{
  padding-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}

#not_found{
  color: white;
  text-align: center;
}

#report{
  padding-top: 5px;
  font-size: 16px;
  color: #424242;
}

</style>
