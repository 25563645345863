<template>    
    <div id= "loading" v-if="loading == true" class="mt-3 mb-3 text-center">
        <img src="@/assets/img/loading.gif"/><br>
        <span><b>{{ message }}</b></span>
    </div>              
</template>

<script>
    export default {
    name: "Loading",

    props: {

        message: {
            type: String,
            required: true,
            default : 'Loading'
        },

        loading: {
            type: Boolean,
            required: true,
            default: true
        } 
    }
}
</script>

<style>
</style>