<template>
  <nav aria-label="breadcrumb">
    <h6 class="mb-0 font-weight-bolder" :class="color">
    </h6>
  </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      type: String,
      required: "",
    },
    color: {
      type: String,
      default: "text-dark",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
