<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-6 mb-lg-0">
          <div
            class="text-sm text-center copyright text-muted"
          >
          <a
            href="https://www.lys.academy/br/"
            class="font-weight-bold text-muted"
            target="_blank"
            
          >
          © 2024 LYS ACADEMY Ltda.</a> CNPJ: 31.454.568/0001-06 | Política De Privacidade
          </div>
        </div>
        <div class="col-lg-6">
          <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a
              href="https://www.lys.academy/br/sobre-a-lys/"
              class="nav-link text-muted"
              target="_blank"
              >Lys Academy</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.lys.academy/br/solucoes/metodologia-exclusiva/"
              class="nav-link text-muted"
              target="_blank"
              >Conheça mais</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.lys.academy/br/blog/"
              class="nav-link text-muted"
              target="_blank"
              >Blog</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.lys.academy/br/contato/"
              class="nav-link pe-0 text-muted"
              target="_blank"
              >Contato</a
            >
          </li>
        </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "app-footer",
};
</script>
