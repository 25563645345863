<template>
    <div ref="chart"></div>
</template>

<script>
    export default {  
        name: 'google-chart',
        props: {
            chartType: {
                type: String,
                required: true
            },
            data: {
                type: Array,
                required: true
            },
            options: {
                type: Object,
                default: () => ({})
            },
            width: {
                type: String,
                default: '100%' // Valor padrão se não for fornecido
            },
            showLegend: {
                type: Boolean,
                default: true // Valor padrão para exibir a legenda
            }
        },
        
        mounted() {
            this.drawChart();
        },
        
        watch: {
            data: function () {
                this.drawChart();
            },
            options: {
                deep: true,
                handler() {
                    this.drawChart();
                }
            },
        },
        
        methods: {
            drawChart() {
                // Carregar a API do Google Charts
                google.charts.load('current', { packages: ['corechart', 'table'] });
                google.charts.setOnLoadCallback(() => {
                    const chartData = google.visualization.arrayToDataTable(this.data);
                    const optionsWithWidth = { 
                        ...this.options, 
                        width: this.width,
                        legend: this.showLegend ? undefined : 'none',
                        title: this.options.title || ''
                    };
                    const chart = new google.visualization[this.chartType](this.$refs.chart);
                    chart.draw(chartData, optionsWithWidth);
                });
            }
        }
    }
</script>

<style scoped>
</style>