<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="d-flex justify-content-between w-100">
        <div class="px-1">
          <router-link
            :to="{ name: 'Pills' }"
            class="btn bg-gradient-info top-button"
          >
            <i class="bi bi-arrow-left-circle"></i> &nbsp;&nbsp;Back
          </router-link>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2 mb-4">
            <div class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3">
              <h6 class="col text-white text-capitalize ps-3">Pill Answers</h6>
            </div>

            <Loading message="Loading pill answers" :loading = "loading"/>

            <div v-if="loading == false" class="col-lg-20 mb-md-0 mb-4">
              <div class="d-flex justify-content-center col-12 gap-3 mb-5 mt-5">
                <div class="card col-lg-6 col-md-12 col-sm-12 shadow-dark">
                  <div class="card-header py-3 px-4 pt-2">
                    <div class="shadow text-center text-light fw-bold border-radius-xl mt-n4 p-2 position-absolute bg-gradient-secondary shadow-secondary">
                      Total Answers X Question
                    </div>
                  </div>

                  <div class="card-body">
                    <google-chart v-if="hasApiReturn == true"
                      :chartType="'ColumnChart'"
                      :data="indicatorForQuestionData"
                      :options="indicatorForQuestionOptions"
                      :showLegend="false"
                    />
                  </div>
                </div>

                <div class="card col-lg-6 col-md-12 col-sm-12 shadow-dark">
                  <div class="card-header py-3 px-4 pt-2">
                    <div class="shadow text-center text-light fw-bold border-radius-xl mt-n4 p-2 position-absolute bg-gradient-secondary shadow-secondary">
                      Total Options X Question
                    </div>
                  </div>

                  <div class="card-body">
                    <google-chart v-if="hasApiReturn == true"
                      :chartType="'Table'"
                      :data="indicatorForAnswerData"
                      :options="indicatorForAnswerOptions"
                      :showLegend="false"
                    />
                  </div>
                </div>
              </div>

              <div class="table-responsive align-items-center mb-0 rounded-3" style = "margin: 0px 20px;">
                <table class="table-responsive table table-striped table-hover">
                    <thead class="bg-gradient-secondary fw-bold text-light">
                        <tr>
                            <th class="text-left px-2">Pill registration</th>
                            <th class="text-center">Question</th>
                            <th class="text-center">Answer</th>
                            <th class="text-center">Data</th>
                            <th class="text-center">Created</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="answers in allPillAnswers" :key="answers.id">
                            <td class="text-left align-middle">{{ pillName }} - {{ answers.student_phone_number }} ({{ answers.student_name }})</td>
                            <td class="text-center align-middle">{{ answers.question }}</td>
                            <td class="text-center align-middle">{{ answers.answer }}</td>
                            <td class="text-center align-middle">{{ answers.data }}</td>
                            <td class="text-center align-middle">{{ formatDate(answers.created) }}</td>
                        </tr>
                    </tbody>
                </table>    
              </div>

              <div class="row mt-3">
                <nav aria-label="...">
                  <ul class="pagination justify-content-center align-items-center gap-3">
                    <li v-if="this.currentPage > 1" class="page-item">
                      <i class="bi bi-caret-left-fill page-link fs-6" @click="previousPage(this.currentPage)"></i>
                    </li>

                    <li class="page-item fs-5 disabled">{{ this.currentPage }} of {{ this.totalPages }}</li>

                    <li v-if="this.currentPage < this.totalPages" class="page-item">
                      <i class="bi bi-caret-right-fill page-link fs-6" @click="nextPage(this.currentPage)"></i>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {http, http_file, url_from_app_user} from "../http/index";
import Loading from "@/views/components/Loading.vue";
import GoogleChart from "./components/GoogleChart.vue";
import lysLogo from "@/assets/img/icon-lys.png";

export default {
  name: "PillRegistrations",

  components : {    
    Loading,
    GoogleChart,
  },

  data() {
    return {
      lysLogo,
      loading: true,
      currentPage: 1,
      allPillAnswers: [],
      totalPages: 0,
      pillName: '',
      indicator_for_question: {},
      indicator_for_answer_counts: {},
      hasApiReturn: false,
      indicatorForQuestionData: [],
      indicatorForQuestionOptions: {},
      indicatorForAnswerData: [],
      indicatorForAnswerOptions: {},
    };
  },

  mounted() {
    this.getPillAnswers();
  },

  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;      
    },

    async getPillAnswers() {
      var payload = {
        token_user: localStorage.getItem("token"),
        current_page: this.currentPage,
        pill_id: this.$route.params.id,
      };

      var mountedIndicatorQuestionData = [['Question', 'Total Answers']];
      var mountedIndicatorOptionData = [['Question', 'Option', 'Total answers']];

      this.loading = true;

      http.post("/api/v1/dashboard/get_pills_answers/", payload)
      .then((response) => {
        this.allPillAnswers = response.data.data;
        this.totalPages = response.data.total_pages;
        this.pillName = response.data.pill_name;
        this.indicator_for_question = this.sortObjectByIndex(response.data.indicator_for_question);
        this.indicator_for_answer_counts = this.sortObjectByIndex(response.data.indicator_for_answer_counts);

        const indicator_question_keys = Object.keys(this.indicator_for_question);
        
        indicator_question_keys.forEach(key => {
          mountedIndicatorQuestionData.push([key.toString(), parseInt(this.indicator_for_question[key])])
        });
        
        this.indicatorForQuestionData = mountedIndicatorQuestionData;
        this.indicatorForQuestionOptions = {
          title: '',
          legend: { position: 'none' }
        };

        const indicator_options_keys = Object.keys(this.indicator_for_answer_counts);

        indicator_options_keys.forEach(key => {
            const options_keys = Object.keys(this.indicator_for_answer_counts[key]);

            options_keys.forEach(opt_key => {
                mountedIndicatorOptionData.push([key.toString(), opt_key.toString(), parseInt(this.indicator_for_answer_counts[key][opt_key])]);
            });
        });

        this.indicatorForAnswerData = mountedIndicatorOptionData;
        this.indicatorForAnswerOptions = {
            showRowNumber: true,
            width: '100%',
            height: '100%'
        };
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
        this.hasApiReturn = true;
      })
    },

    sortObjectByIndex(unsorted_object) {
      let mapped_object = Object.keys(unsorted_object).map(function (key, index) {
        return { key: key, value: unsorted_object[key] };
      });

      mapped_object.sort(function (a, b) {
        return +(a.key > b.key) || +(a.key === b.key) - 1;
      });

      let sorted_object = {};
      mapped_object.forEach(function (item) {
        sorted_object[item.key] = item.value;
      });

      return sorted_object;
    },    

    previousPage(actual_page) {
      let new_currentPage = actual_page - 1;
      this.currentPage = new_currentPage;

      this.getAllPushBatches();
    },

    nextPage(actual_page) {
      let new_currentPage = actual_page + 1;
      this.currentPage = new_currentPage;

      this.getAllPushBatches();
    },
  }
}
</script>

<style scoped>
</style>