<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3">
              
              <h6 class="col text-white text-capitalize ps-3">User Control</h6>
              <div class="col" v-if="loading == false">
                <a v-if="this.data_users.users.length < this.data_users.total_users" class="btn-outline-dark btn-inline btn-sm m-1" data-bs-toggle="modal" data-bs-target="#createnewuser" id="btn">
                  <i class="bi bi-person-add"></i>
                </a>
                <div class="btn-outline-dark btn-inline btn-sm m-1" id="btn">
                  <i class="bi bi-clipboard-plus"></i> {{this.data_users.users.length}} de {{ this.data_users.total_users }}
                </div>
              </div>
            </div>
          </div>

          <Loading message="Loading your users" :loading = "loading"/>

          <UserTable :data_users ="data_users" v-if="loading == false"></UserTable>

          <!-- Modal -->
          <div class="modal fade" id="createnewuser" tabindex="-1" aria-labelledby="createnewuserLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="createnewuserlLabel">Cadastro de Usuários</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                  <div class="card border" id="card-newuser">
                    <div class="card-body">
                      <div>
                        <label for="floatingInput" class="form-label">Nome</label>
                        <div class="input-group input-group-outline">
                          <input id="floatingInput" type="text" class="form-control form-control-default" name="floatingInput" placeholder="name@example.com">
                        </div>
                      </div>

                      <div class="mt-2">
                        <label for="floatingLastName" class="form-label">Sobrenome</label>
                        <div class="input-group input-group-outline mt-2">
                          <input id="floatingLastName" type="text" class="form-control form-control-default" name="floatingLastName" placeholder="Sobrenome">
                        </div>
                      </div>                      

                      <div class="mt-2">
                        <label for="floatingEmail" class="form-label">Email</label>
                        <div class="input-group input-group-outline mt-2">
                          <input id="floatingEmail" type="email" class="form-control form-control-default" name="floatingEmail" placeholder="name@example.com">
                        </div>
                      </div>

                      <div class="mt-2">
                        <label for="floatingPassword" class="form-label">Senha Inicial</label>
                        <div class="input-group input-group-outline mt-2">
                          <input id="floatingPassword" type="password" class="form-control form-control-default" name="floatingPassword" placeholder="Password">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Sair</button>
                  <button type="button" class="btn btn-primary" @click="Create_user">Salvar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

#td-list-users{
  color: white;
  text-align: center;
  box-shadow: 0px 0px 5px 0px #000000;
  border-radius: 3rem;
  padding: 0.3rem 0.1rem 0.3rem 0.0rem;
  width: 1px;
  font-size: 0.7rem;
  font-weight: 500;
  background-color: #1e1e2f;
  transition: all 0.2s ease-in-out;
  width: 10px;
  height: 30px;  
}

#btn {
  color: white;
  text-align: right;
  box-shadow: 0px 0px 5px 0px #000000;
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 100;
  background-color: #1e1e2f;
  transition: all 0.2s ease-in-out;
  float: right; 
  
  }
.label{
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background-color: #1e1e2f;
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 5px 0px #000000;
  transition: all 0.2s ease-in-out;
}
#card-newuser{
  padding: 1rem;
  border-radius: 1rem;
  font-size: 5rem;
}
</style>

<script>
import UserTable from "@/views/components/UserTable.vue";
import Loading from "@/views/components/Loading.vue";
import {http} from "../http/index";

export default {

  name: "ControUsers",
  
  components : {
    UserTable, Loading
  },

  mounted() {

    setTimeout(() => {
      this.get_users_from_API()
     }, 3000); 

  },

  data() {
    return {
      actualy_user_token: localStorage.getItem("token"),
      email_selected: null,
      name: null,
      sobrenome: null,
      old_password: null,
      new_password: null,
      data_users: [],
      loading: true
    };
  },

  methods: {

    get_users_from_API: function() {

      var payload = {
         token: localStorage.getItem("token")
      }

      http.post("/api/v1/dashboard/list-users/", payload)
      .then((response) => {
        this.data_users = response.data        
      })
      .catch(error => {
      }).finally(() => {
        this.loading = false;
      })
    }
  }
};
</script>
