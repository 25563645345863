<template>
  <div class="py-4 container-fluid">
    <iframe 
      :src="url_iframe" 
      width="100%" 
      height="1000px" 
      frameborder="0" 
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
import {http, http_file, url_from_app_user, url_dashboard} from "../http/index";

export default {
  name: "dashboard-default-lys",

  data() {
    return {
      token_user: localStorage.getItem("token"),
      url_iframe: url_dashboard + "?t=" + localStorage.getItem("token"),
    };
  },
  computed: {
    

  },
  components: {},
};
</script>