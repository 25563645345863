<template>
  <div class="container-fluid py-4">
    
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2  mb-4">
            <div
              class="row bg-gradient-info shadow-black border-radius-lg pt-4 pb-3 mb-3"> 
              <h6 class="col text-white text-capitalize ps-3">Participants</h6>
            </div>    
            
            <div
              v-if="this.showAlert"
              :class="[
                'alert',
                this.responseStatusCode == 'success'
                  ? 'alert-success'
                  : this.responseStatusCode == 'error'
                  ? 'alert-danger'
                  : this.responseStatusCode == 'pending'
                  ? 'alert-warning'
                  : 'alert-info',
              ]"
              role="alert"
            >
              <span class="text-alert">{{ this.alertMessage }}</span>
            </div>

            <div v-if="this.view_secndary_info == false && this.showPushBathDetails == false && allenrollments!=null" class="col-lg-12 position-relative z-index-2 mt-5">
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-6">
                    <mini-statistics-card
                      :title="{ text: 'Total Participants', value: this.data_api_enrollments_list.total_enrollments }"
                      detail="Total Participants from course"
                      :icon="{
                        name: 'bookmark',
                        color: 'text-white',
                        background: 'info',
                      }"
                    />
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                    <mini-statistics-card
                      :title="{ text: 'Total Initials', value: this.data_api_enrollments_list.total_enrollments_initial }"
                      detail="Total Initial Participants"
                      :icon="{
                        name: 'leaderboard',
                        color: 'text-white',
                        background: 'info',
                      }"
                    />
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                    <mini-statistics-card
                      :title="{ text: 'Total Active', value: this.data_api_enrollments_list.total_enrollments_active }"
                      detail="Total Active Participants"
                      :icon="{
                        name: 'person',
                        color: 'text-white',
                        background: 'success',
                      }"
                    />
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                    <mini-statistics-card
                      :title="{ text: 'Total Completed', value: this.data_api_enrollments_list.total_enrollments_completede }"
                      detail="Total Participants Completed"
                      :icon="{
                        name: 'check',
                        color: 'text-white',
                        background: 'success',
                      }"
                    />
                  </div>
                </div>
            </div>
            
            <div class="pb-2 pt-0 mt-0 d-flex justify-content-start gap-4 mb-4">
              <div class="col-6" v-if="this.view_secndary_info == false && this.showPushBathDetails ==false">
                <label class="mt-3">Project</label>
                <select  class="form-select form-select-lg" v-model="project" @change="selectedProject">
                  <option v-if="projects == null" value="">Charger ... </option>
                  <option @click="selct_course(project.course_id)" v-for="project in projects" :value="project.course_id" :key="project.course_id">{{ project.project_name }} -- {{ project.course_name }}</option>
                </select>
              </div>


              <div v-if="project != null && this.view_secndary_info == false && this.showPushBathDetails ==false" class="p-0 d-flex w-100">
                <div class="w-100">
                  <label class="mt-3">Filter</label>
                  <div class="d-flex align-items-center gap-2">
                    <input type="text" class="form-control form-control-lg custom-input" v-model="searchFilter" placeholder="Search by Name, Phone or UTM">

                    <div class="d-flex algin-items-end">                      
                      <button @click="getAllenrollments()" class="btn bg-gradient-info my-0">FILTER</button>
                    </div>
                  </div>
                </div>

              </div>              
            </div>

            <div v-if="this.view_secndary_info == true && this.showLP == true">
              <button @click="controlSecundaryVeiw(false, null)" class="btn bg-gradient-info" ><i class="bi bi-arrow-left-circle"> Back</i></button>
              <div  v-if="data_course.is_privete == true" >
                <iframe v-if="this.data_api_enrollments_list != null" :src="lp_url_from_course" width="100%" height="800px"></iframe>
                <Loading message="Loading your reports" :loading = "loading"/>
              </div>
              
              <div  v-if="data_course.is_privete == false">
                <div class="card">
                  <div class="card-body">
                    <h4>This Landing Page is not Privet</h4>
                    <p>For more information, please contact the responsible for the course</p>
                  </div>
                </div>
              </div>
            </div>
            
           

            
              <div v-if="this.view_secndary_info == false && this.showPushBathDetails == false && allenrollments!=null" class="card px-0 pb-2">
                <div v-if="project != null" class="w-100 d-flex justify-content-between align-items-center my-0 px-1">
                  <div>
                    <h5>Selected: {{ this.data_course.external_exercise_id }} -- {{ this.data_course.name }}</h5>
                  </div>                  
                  <div v-if="project != null && this.view_secndary_info == false && this.showPushBathDetails ==false"  class="d-flex justify-content-end align-items-center gap-2">
                    <button @click="insertEnrollmentFromLP()" class="btn col-md-12 bg-gradient-info p-1 fs-5"><i class="bi bi-person-plus"></i></button>
                    <button @click="importContainer()" class="btn col-md-12 bg-gradient-info p-1 fs-5"><i class="bi bi-person-up"></i></button>
                  </div>                
              </div>

              <div v-if="showAlertStatus" :class="['alert', responseStatusCode != 200 ? 'alert-danger': 'alert-success', 'alert-dismissible', 'fade', 'show']" role="alert">
                <span class="text-alert">{{ alertMessage }}</span>
                <button @click="closeAlert()" type="button" class="btn-close d-flex align-items-center px-4" data-bs-dismiss="alert" aria-label="Close">
                  <i class="bi bi-x fs-3"></i>
                </button>
              </div> 

              <div class="table-responsive p-0">                
                <table class="table table-striped table-hover align-items-center mb-4">
                  <thead class="bg-dark">
                    <tr>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Number Inscription                        
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Participant Status
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Participant Name                        
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        UTM                      
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Inscription                        
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Modified                        
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Deadline change status
                      </th>
                      <th
                        class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                      >
                        Options                        
                      </th>                      
                    </tr>
                  </thead>
                  
                  <tbody v-if="loading==false">
                    <tr v-for="enrollment in allenrollments" :key="enrollment.id">
                      <td class="align-middle text-center text-sm">
                        <div class="d-flex px-2 py-1 justify-content-center align-items-center">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <h6 class="mb-0 text-sm">{{enrollment.id}}</h6>
                          </div>
                        </div>
                      </td>

                      <td class="align-middle text-center text-sm">
                        <span v-if="enrollment.status == 'active'" class="badge badge-sm bg-gradient-success col-md-7"
                          >{{enrollment.status}}</span
                        >
                        <span v-if="enrollment.status == 'completed'" class="badge badge-sm bg-gradient-success col-md-7"
                          >{{enrollment.status}}</span
                        >
                        <span v-if="enrollment.status == 'initial'" class="badge badge-sm bg-gradient-info col-md-7"
                          >{{enrollment.status}}</span
                        >
                        <span v-if="enrollment.status == 'missing_token'" class="badge badge-sm bg-gradient-info col-md-7"
                          >{{enrollment.status}}</span
                        >
                        <span v-if="enrollment.status == 'inactive'" class="badge badge-sm bg-gradient-danger col-md-7"
                          >{{enrollment.status}}</span
                        >
                      </td>                     

                      <td class="align-middle text-center text-sm">
                        <div class="d-flex px-2 py-1 justify-content-center align-items-center">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <h6 class="mb-0 text-sm">{{enrollment.phone_student}}</h6>
                            <p class="text-xs text-secondary mb-0">
                              {{ enrollment.name_student }}
                            </p>
                          </div>
                        </div>
                      </td>

                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{enrollment.utm}}</span
                        >
                      </td>

                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{formatDate(enrollment.created)}}</span
                        >
                      </td>

                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold"
                          >{{formatDate(enrollment.modified)}}</span
                        >
                      </td>

                      <td class="align-middle text-center">
                        <span class="text-secondary text-xs font-weight-bold">
                          {{ enrollment.deadlineChangeStatus ? formatDate(enrollment.deadlineChangeStatus) : '' }}
                        </span>
                      </td>

                      <td class="align-middle text-center">
                        <div class="m-1">
                          <button v-bind:title="buttonTitle.info" @click="controlSecundaryVeiw(true, enrollment.id)" class="btn bg-gradient-warning my-0 fs-6 m-1"><i class="bi bi-person-lines-fill"></i></button> 
                        
                          <button v-bind:title="buttonTitle.changeStatus" v-if="enrollment.status == 'active'" class="btn bg-gradient-success my-0 m-1" data-bs-toggle="modal" data-bs-target="#modalChangeEnroll" @click="newStatus(enrollment.id,'inactive', enrollment.status, enrollment.deadlineChangeStatus)"><i class="bi bi-toggle2-on fs-6"></i></button>
                          
                          <button v-bind:title="buttonTitle.changeStatus" v-else-if="enrollment.status == 'inactive'" class="btn bg-gradient-danger my-0 m-1" data-bs-toggle="modal" data-bs-target="#modalChangeEnroll" @click="newStatus(enrollment.id,'active', enrollment.status, enrollment.deadlineChangeStatus)"><i class="bi bi-toggle2-off  fs-6"></i></button>
                          
                          <button v-bind:title="buttonTitle.changeStatus" v-else class="btn bg-gradient-secondary my-0 m-1" disabled ><i class="bi bi-x-circle fs-6 "></i></button>

                          <button v-bind:title="buttonTitle.block" v-if="enrollment.student_blocked == false" data-bs-toggle="modal" data-bs-target="#modalBlockStudent" @click="_block(enrollment.id, false)" class="btn bg-gradient-success my-0 m-1 fs-6"><i class="bi bi-unlock fs-6"></i></button>
                          
                          <button v-bind:title="buttonTitle.unlock" v-else data-bs-toggle="modal" data-bs-target="#modalBlockStudent" @click="_block(enrollment.id, true)" class="btn bg-gradient-danger my-0 m-1 fs-6"><i class="bi bi-lock"></i></button>

                        </div>
                      </td>
                      
                    </tr>
                    
                  </tbody>
                </table>

                <Loading message="Loading your reports" :loading = "loading"/>

                <nav v-if="this.total_pages > 1" aria-label="Page navigation example">
                  <ul class="pagination justify-content-center">
                    <li v-if="this.page_data_enrollment <= this.total_pages && this.page_data_enrollment > 1" class="page-item">
                      <a @click="decrementPage" class="page-link"><i class="bi bi-chevron-double-left"></i></a>
                    </li>
                    <li class="page-item px-2"><a class="page-link">{{ this.page_data_enrollment }}</a></li>
                    <li v-if="this.total_pages > 1" class="page-item d-flex align-items-center">of</li>
                    <li v-if="this.total_pages > 1" class="page-item px-2" style="margin-left: 0.2%;"><a class="page-link">{{ this.total_pages }}</a></li>
                    <li v-if="this.page_data_enrollment < this.total_pages" class="page-item">
                      <a @click="incrementPage" class="page-link"><i class="bi bi-chevron-double-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <!-- Secondary info  enroll-->
            <div v-if="view_secndary_info == true && this.showImportContainer == false && this.showLP == false">
              <button @click="controlSecundaryVeiw(false, null)" class="btn bg-gradient-info" ><i class="bi bi-arrow-left-circle"> Back</i></button>
              <div class="card">
                <div class="row mx-3 my-3 d-flex gap-4 justify-content-center">
                  <div class="card border border-info col" style="margin-bottom: 1%;">
                    <h6>Course Info</h6>
                    <b>{{ this.data_secundary_info.course_info }}</b>
                  </div>

                  <div class="card border border-info col" style="margin-bottom: 1%;">
                    <h6>Course Info</h6>
                    <b>{{ this.data_secundary_info.project_info }}</b>
                  </div>

                  <div class="card border border-info col" style="margin-bottom: 1%;">
                    <h6>Sender</h6>
                    <b>{{ this.data_secundary_info.sender_enroll }}</b>
                  </div>
                </div>

                <div class="row mx-3 my-3 d-flex gap-4 justify-content-center">
                  <div class="card border border-info col" style="margin-bottom: 1%;">
                    <h6>Course Info</h6>
                    <b>{{ this.data_secundary_info.status_enroll }}</b>
                  </div>
                  
                  <div class="card border border-info col" style="margin-bottom: 1%;">
                    <h6>Enrollment Created</h6>
                    <b>{{ formatDate(this.data_secundary_info.inscription_data) }}</b>
                  </div>
                  
                  <div class="card col" style="margin-bottom: 1%; opacity: 0;"></div>                  
                </div>
              </div>

              <nav>               
                <div class="nav nav-tabs" id="nav-tab" role="tablist" style="margin-top: 2.5%; margin-bottom: 1%;">
                  <button class="nav-link text-dark font-weight-bold" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Inscriptions Info LP</button>

                  <button class="nav-link text-dark font-weight-bold" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Inscriptions Info Bot</button>

                  <button class="nav-link text-dark font-weight-bold" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Depositions</button>                  
                </div>
              </nav>

              <div class="tab-content" id="nav-tabContent">                
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                  <div v-if="data_secundary_info.info_LP.length > 0" class="card d-flex flex-row flex-wrap justify-content-start gap-3 my-3 p-3 border border-secondary">                    
                    <div v-for="data_lp in data_secundary_info.info_LP" :key="data_lp.field_name" class="w-30 m-0 p-0">
                      <div class="form-group">
                        <label class="form-label fs-6">{{ data_lp.field_name }}</label>
  
                        <input type="text" :value="data_lp.response_data" class="card px-3 form-control border text-secondary fs-6 bg-white" readonly>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                  <div v-if="data_secundary_info.info_bot.length > 0" class="card d-flex flex-row flex-wrap justify-content-start gap-3 my-3 p-3 border border-secondary">                    
                    <div v-for="data_bot in data_secundary_info.info_bot" :key="data_bot.field_name" class="w-30 m-0 p-0">
                      <div class="form-group">
                        <label class="form-label fs-6">{{data_bot.fild_name}}</label>
  
                        <input type="text" :value="data_bot.response_data" class="col card px-3 form-control border text-secondary fs-6 bg-white" readonly>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                  <div class="accordion-1">
                    <div class="container">                      
                      <div class="row">
                        <div class="col-md-10 mx-auto">
                          <div v-for="data_depositions in data_secundary_info.depositions" :key="data_depositions.id" class="accordion" :id="'accordionDep'+data_depositions.id">
                            <div class="accordion-item mb-3">
                              <h5 class="accordion-header" id="headingOne">
                                <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne'+data_depositions.id" aria-expanded="false" :aria-controls="'collapseOne'+data_depositions.id">
                                  {{data_depositions.lesson_reference}}
                                  <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                  <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                </button>
                              </h5>

                              <div :id="'collapseOne'+data_depositions.id" class="accordion-collapse collapse" aria-labelledby="headingOne" :data-bs-parent="'#accordionDep'+data_depositions.id" style="">
                                <div class="accordion-body text-sm opacity-8">
                                  <div class="d-flex justify-content-center align-items-center gap-3">
                                    <div class="d-flex justify-content-center card border border-secondary p-3">
                                      <div class="card-header text-center">
                                        <h6>Score</h6>
                                      </div>

                                      <div class="card-body text-center fs-6">
                                        <div v-if="data_depositions.score == 1">
                                          <i class="bi bi-star-fill" style="color: maroon;"></i>
                                        </div>
  
                                        <div v-if="data_depositions.score == 2">
                                          <i class="bi bi-star-fill" style="color: maroon;"></i>
                                          <i class="bi bi-star-fill" style="color: darkred;"></i>        
                                        </div>
  
                                        <div v-if="data_depositions.score == 3">
                                          <i class="bi bi-star-fill" style="color: maroon;"></i>
                                          <i class="bi bi-star-fill" style="color: darkred;"></i>
                                          <i class="bi bi-star-fill" style="color: sienna;"></i>
                                        </div>
  
                                        <div v-if="data_depositions.score == 4">
                                          <i class="bi bi-star-fill" style="color: maroon;"></i>
                                          <i class="bi bi-star-fill" style="color: darkred;"></i>
                                          <i class="bi bi-star-fill" style="color: sienna;"></i>
                                          <i class="bi bi-star-fill" style="color: orange"></i>
                                        </div>
  
                                        <div v-if="data_depositions.score == 5">
                                          <i class="bi bi-star-fill" style="color: maroon;"></i>
                                          <i class="bi bi-star-fill" style="color: darkred;"></i>
                                          <i class="bi bi-star-fill" style="color: sienna;"></i>
                                          <i class="bi bi-star-fill" style="color: orange"></i>
                                          <b><i class="bi bi-star-fill" style="color: gold;"></i></b>
                                        </div>
                                      </div>
                                    </div>

                                    <div class="d-flex justify-content-center card border border-secondary p-3">
                                      <div class="card-header text-center">
                                        <h6>Deposition</h6>
                                      </div>
                                      <div class="card-body text-center">                                        
                                        <b class="fs-6 text-center">{{data_depositions.text_deposition}}</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.showImportContainer">
              <div class="row">
                <div class="d-flex justify-content-between w-100">
                  <button @click="returnToMainPage()" class="btn bg-gradient-info top-button" ><i class="bi bi-arrow-left-circle"> Back</i></button>
                  
                  <div>
                    <button @click="CSVExampleDownload()" class="btn bg-gradient-info top-button" ><span class="bi bi-download"> Example</span></button>
                    <button @click="pushBatchForm()" class="btn bg-gradient-info top-button" ><span class="bi bi-cloud-arrow-up"> Import</span></button>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div v-if="this.showPushBatchForm" class="push-batch-form">
                  <div class="d-flex justify-content-center w-100">
                    <div class="card w-50 border">
                      <div class="card-header pt-3 pb-0">
                        <h4>Import Participants in Batch</h4>
                      </div>

                      <div class="alert alert-warning d-flex align-items-center m-4" role="alert">
                        <i class="fs-2 text-alert m-3 bi bi-exclamation-triangle"></i>
                        <div class="text-alert">
                          <p>Download the example CSV by clicking on the 'EXAMPLE' button at the top of this page;</p>
                          <p>Fill in the data for import WITHOUT EDITING the column names;</p>
                          <p>
                            The column containing the participant number will be used to register the participant in the course.
                            <br>
                            So, make sure the number is correct and in the proper format;
                          </p>
                          <p>Select the file and click 'IMPORT'.</p>
                        </div>
                      </div>
    
                      <div class="card-body">
                        <div>
                          <label for="identifier" class="form-label">Identifier</label>
                          <div class="input-group input-group-outline">
                            <input id="identifier" type="text" class="form-control form-control-default" name="identifier" placeholder="Identifier">
                          </div>
                        </div>

                        <div class="mt-2">
                          <label for="file" class="form-label">CSV File</label>
                          <div class="input-group input-group-outline">
                            <input id="file" type="file" class="form-control" name="file" placeholder="File">
                          </div>
                        </div>

                        <div class="mx-auto d-flex justify-content-around mt-3">                          
                          <button @click="cancelPushBatch()" class="btn mb-0 bg-gradient-danger btn-lg w-40 top-button">Cancel</button>
                          <button @click="createPushBatch()" class="btn mb-0 bg-gradient-success btn-lg w-40 top-button" :disabled="this.isImporting">Import</button>
                        </div>
                      </div>                      
                    </div>
                  </div>
                </div>
              </div>

              <table v-if="pushBatches!=null && pushBatches!=0" class="table table-striped table-hover align-items-center mb-0 mt-4">
                <thead class="bg-dark">
                  <tr>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Identifier
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Created by
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Status
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Numbers Count
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Processed Numbers
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Created
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Modified
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Details
                    </th>
                  </tr>
                </thead>
                
                <tbody v-if="loading==false">
                  <tr v-for="pushBatch in pushBatches" :key="pushBatch.id">
                    <td >
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{pushBatch.identifier}}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{pushBatch.created_by_email}}</span
                      >
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span v-if="pushBatch.status == 'INITIAL'" class="badge badge-sm bg-gradient-success">
                        Registered
                      </span>
                      <span v-if="pushBatch.status == 'RUNNING'" class="badge badge-sm bg-gradient-primary">
                        {{pushBatch.status}}
                      </span>
                      <span v-if="pushBatch.status == 'FINISHED'" class="badge badge-sm bg-gradient-success">
                        {{pushBatch.status}}
                      </span>
                      <span v-if="pushBatch.status == 'FAILED'" class="badge badge-sm bg-gradient-danger">
                        {{pushBatch.status}}
                      </span>
                      <span v-if="pushBatch.status == 'REGISTERING'" class="badge badge-sm bg-gradient-info">
                        {{pushBatch.status}}
                      </span>
                      <span v-if="pushBatch.status == 'WAITING'" class="badge badge-sm bg-gradient-warning">
                        {{pushBatch.status}}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{pushBatch.numbers_count}}</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{pushBatch.processed_numbers}}</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{formatDate(pushBatch.created)}}</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{formatDate(pushBatch.modified)}}</span
                      >
                    </td>
                    <td class="align-middle">
                      <button @click="pushBathDetails(pushBatch.id)" class="btn bg-gradient-info my-0">
                        <i class="bi bi-plus-circle"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Loading message="Loading your reports" :loading = "loading"/>
            </div>

            <div v-if="this.showPushBathDetails == true">
              <button @click="returnToImportContainer()" class="btn bg-gradient-info top-button" ><i class="bi bi-arrow-left-circle"> Back</i></button>

              <table class="table table-striped table-hover align-items-center mb-0 mt-4">
                <thead class="bg-dark">
                  <tr>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Number
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Status
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Created
                    </th>
                    <th
                      class="text-center text-uppercase text-white text-sm font-weight-bolder opacity-7"
                    >
                    Modified
                    </th>
                  </tr>
                </thead>
                
                <tbody v-if="loading==false">
                  <tr v-for="pushLine in pushLines" :key="pushLine.id">
                    <td >
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{pushLine.number}}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center text-sm">
                      <span v-if="pushLine.status == 'INITIAL'" class="badge badge-sm bg-gradient-info">
                        {{pushLine.status}}
                      </span>
                      <span v-if="pushLine.status == 'RUNNING'" class="badge badge-sm bg-gradient-primary">
                        {{pushLine.status}}
                      </span>
                      <span v-if="pushLine.status == 'FINISHED'" class="badge badge-sm bg-gradient-success">
                        {{pushLine.status}}
                      </span>
                      <span v-if="pushLine.status == 'FAILED'" class="badge badge-sm bg-gradient-danger">
                        {{pushLine.status}}
                      </span>
                      <span v-if="pushLine.status == 'REGISTERING'" class="badge badge-sm bg-gradient-info">
                        {{pushLine.status}}
                      </span>
                      <span v-if="pushLine.status == 'WAITING'" class="badge badge-sm bg-gradient-warning">
                        {{pushLine.status}}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{formatDate(pushLine.created)}}</span
                      >
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold"
                        >{{formatDate(pushLine.modified)}}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <Loading message="Loading your reports" :loading = "loading"/>
            </div>

          </div>
          <!-- modal change enroll -->
          <div class="modal" id="modalChangeEnroll" tabindex="-1" aria-labelledby="modalChangeEnrollLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalChangeEnrollLabel">Change Enrollment</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <h7>Change the status of the participant?</h7>                   
                    <div v-if="this.old_status == 'active'" class="mt-2">  


                      <label for="subscriptions_deadline" class="form-label">Do you want a specific date to change the status to deactivated?        <br> (UTC-3)</label>                    
                      <div class="input-group input-group-outline">                      
                        <input id="subscriptions_deadline" type="date" placeholder="dd/mm/yyyy" class="form-control form-control-default" v-model="this.deadline" name="subscriptions_deadline">
                      </div>
                      <div class="input-group input-group-outline">

                        <small class="text-danger" >Actual deadline: {{ this.old_deadline }}</small>                     
                        <small v-if=" this.deadline == null && this.old_deadline != 'No deadline'" for="subscriptions_deadline" class="text-danger">If you save without a date, it will change the status now.</small>                        
                      </div>
                      
                    </div>
                    <div v-if=" this.old_status == 'inactive'" class="mt-2">
                      <label for="subscriptions_deadline" class="form-label">Do you want a specific date to change the status to activated?        <br> (UTC-3)</label>                      
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn bg-gradient-danger" data-bs-dismiss="modal" @click="clearStatus()">Close</button>
                  <button type="button" class="btn bg-gradient-success" @click="changeStatus()">Save changes</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal block student -->
          <div class="modal fade" id="modalBlockStudent" tabindex="-1" aria-labelledby="modalBlockStudentLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="modalBlockStudentLabel">Block Student</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div v-if=" this.statusBlock == false" class="modal-body text-center" >
                  <div class="input-group input-group-outline">
                    <h6>Do you want to block the student?</h6>
                    <small class="text-danger" >Blocked students will lose all access to the courses on offer.</small> 
                  </div>
                </div>
                <div v-else class="modal-body text-center" >
                  <div v-if=" this.statusBlock == true" class="input-group input-group-outline">                  
                    <h6> Do you want to unblock the student?</h6>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn bg-gradient-danger" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn bg-gradient-success" @click="blockStudent()">Block</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import MaterialButton from "@/components/MaterialButton.vue";
import {http, http_file, url_from_app_user} from "../http/index";
import Loading from "@/views/components/Loading.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";


export default {
  name: "ControUsers",

  components : {
    MaterialButton,
    MiniStatisticsCard,
    Loading
    
  },



  computed :  { 
    
    camposPreenchidos() {

      if(this.project !=  false && this.course != false){
       return true;
      }

    } 

  },

  data() {

    return {
      selectedProj: true,
      projects : null,
      course: '',
      showCourses : false,
      token_user: localStorage.getItem("token"),
      allenrollments: null,
      view_secndary_info: false,
      all_data_enrollment: null,
      total_pages: null,
      page_data_enrollment: 1,
      loading: false,
      data_course: null,
      lp_url_from_course: null,
      showLP: false,
      data_api_enrollments_list: null,
      data_secundary_info: {
        course_info: null,
        project_info: null,
        status_enroll: null,
        sender_enroll: null, 
        inscription_data: null, 
        info_LP: null,
        info_bot: null,
        depositions: null
      },

      pushBatchIdentifier: "",
      pushBatchFile: "",
      showAlert: false,
      showAlertStatus: false,
      showPushBatchForm: false,
      alertMessage: "",
      responseStatusCode: "",
      pushBatches : null,
      showImportContainer : false,

      showPushBathDetails: false,
      pushLines: null,

      isImporting: false,
      searchFilter: "",

      deadline:null,
      old_status: null,
      old_deadline: null,

      buttonTitle:{
        info: "Info student",
        block: "Block student?",
        unlock: "Unblocking student?",
        changeStatus: "Change status"
      },
      enrollment_id: null,
      statusBlock: null,

    };
  },

  mounted() {
    this.getAllProjects()
  },  

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
      
    },

    incrementPage() {
        this.page_data_enrollment++;
        this.getAllenrollments();
    },

    decrementPage() {
      if (this.page_data_enrollment > 1) {
          this.page_data_enrollment--;
          this.getAllenrollments();
      }
    },

    controlSecundaryVeiw(result, enoll_select=null){
      
      this.view_secndary_info = result
      this.showLP = false
      if (result == false){
        this.data_secundary_info.course_info = null
        this.data_secundary_info.project_info = null
        this.data_secundary_info.status_enroll = null
        this.data_secundary_info.inscription_data = null 
        this.data_secundary_info.sender_enroll = null
        this.data_secundary_info.info_LP = null
        this.data_secundary_info.info_bot = null
        this.data_secundary_info.depositions = null
        
        this.data_course = this.data_api_enrollments_list.information_course
        
      }

      if (result == true){
        let payload = {
            token_user: this.token_user,
            course_id: this.project,
            enrollment_id: enoll_select,
            
          }
        
          http.post('/api/v1/dashboard/select-enrollment-from-client/', payload)
          .then((response) => (this.data_secundary_info = response.data.infor_enrollments))
          .finally(() => {
   
              this.data_secundary_info.course_info = this.data_secundary_info.course_info;
              this.data_secundary_info.project_info = this.data_secundary_info.project_info;

              this.data_secundary_info.status_enroll = this.data_secundary_info.status;
              this.data_secundary_info.sender_enroll = this.data_secundary_info.sender;
              this.data_secundary_info.inscription_data = this.data_secundary_info.created;
        
              this.data_secundary_info.info_LP = this.data_secundary_info.info_lp;
              this.data_secundary_info.info_bot = this.data_secundary_info.info_both;
              this.data_secundary_info.depositions = this.data_secundary_info.depositions;              
            });
        }
    },

    getAllenrollments(){
      this.loading = true

      let payload = {
          token_user: this.token_user,
          course_id: this.project,
          pagination: this.page_data_enrollment,
          filter: this.searchFilter
        }
        
        http.post('/api/v1/dashboard/list-enrollment-from-course-client/', payload)
        .then((response) => (this.data_api_enrollments_list = response.data))          
        .finally(() => {
            this.allenrollments = this.data_api_enrollments_list.enrollments
            this.total_pages = this.data_api_enrollments_list.total_pages
            this.loading = false;
            this.data_course = this.data_api_enrollments_list.information_course
            this.lp_url_from_course = url_from_app_user + this.data_course.external_exercise_id + "/access/" + this.token_user;            
          });
        
    }, 

    insertEnrollmentFromLP(){
      this.view_secndary_info = true
      this.showLP = true
      this.loading = true
      setTimeout(() => {
            this.loading = false;
        }, 15000);

    },
    

    getAllProjects(){
        let payload = {
          token: this.token_user
        }
        // headers['Authorization'] = 'Token '+ this.token_user
        http.post('/api/v1/dashboard/list-projects-by-client/', payload)
        .then((response) => (this.projects = response.data.projects))
        .finally(() => {
          
        });

      },

    selectedProject(){
      this.total_pages = null
      this.page_data_enrollment = 1
      this.getAllenrollments()
      
      if(this.project != ''){
      } else{
        this.selectedProj = true;
        this.course = '';
      }
   },

    createPushBatch() {
      if (document.getElementById('identifier').value === '' || document.getElementById('file').files.length === 0) {
          this.showAlert = true
          this.alertMessage = "Please fill in all fields."
          return
      }

      this.pushBatchIdentifier = document.getElementById('identifier').value
      this.pushBatchFile = document.getElementById('file').files[0]

      if (this.pushBatchFile.type !== 'text/csv') {
          this.showAlert = true
          this.alertMessage = "The file selected is not a CSV file."
          this.pushBatchFile = ""
          document.getElementById('file').value = ""
          return
      }

      let reader = new FileReader()
      reader.onload = (event) => {
          let csvContent = event.target.result
      }
      reader.readAsText(this.pushBatchFile)

      this.isImporting = true
      this.showAlert = true
      this.alertMessage = "Importing the Data. Please wait!"
      this.responseStatusCode = "pending"

      let formData = new FormData()
      formData.append('identifier', this.pushBatchIdentifier)
      formData.append('file', this.pushBatchFile)
      formData.append('course_id', this.project)
      formData.append('token', this.token_user)

      http_file.post('/api/v1/dashboard/create-push-batch/', formData)
      .then((response) => {
          this.showAlert = true
          this.alertMessage = response.data.detail
          this.responseStatusCode = "success"
          this.pushBatchIdentifier = ""
          this.pushBatchFile = ""
          document.getElementById('identifier').value = ""
          document.getElementById('file').value = ""

      })
      .catch((error) => {
          this.showAlert = true
          this.alertMessage = error.response.data.detail
          this.responseStatusCode = "error"
          this.pushBatchFile = ""
          document.getElementById('file').value = ""
      })
      .finally(() => {
          this.isImporting = false
          this.showPushBatchForm = false
          this.getAllPushBatches()
      })
    },

    pushBatchForm() {
      this.showPushBatchForm = !this.showPushBatchForm
      this.showAlert = false
      this.alertMessage = ""
    },

    cancelPushBatch() {
      this.showPushBatchForm = false
      this.showAlert = false
      this.alertMessage = ""
      this.pushBatchIdentifier = ""
      this.pushBatchFile = ""
      document.getElementById('identifier').value = ""
      document.getElementById('file').value = ""
    },

    importContainer() {
      this.loading = true
      this.getAllPushBatches()

      this.showImportContainer = true
      this.view_secndary_info = true
    },

    getAllPushBatches() {
      let payload = {
          token: this.token_user,
          course_id: this.project
      }

      http.post('/api/v1/dashboard/list-push-batch-by-user/', payload)
      .then((response) => {
          this.pushBatches = response.data;
      })
      .catch((error) => {
      })
      .finally(() => {
          this.loading = false
      })
    },

    returnToMainPage() {
      this.showImportContainer = false
      this.view_secndary_info = false
      this.showPushBatchForm = false
      this.showAlert = false
      this.alertMessage = ""
    },

    CSVExampleDownload() {
      let payload = {
          course_id: this.project
      }

      let listFields

      this.showAlert = true
      this.alertMessage = "Downloading the example CSV file. Please wait!"
      this.responseStatusCode = "pending"

      http.post('/api/v1/dashboard/list-fields-by-course/', payload)
      .then((response) => {
        listFields = response.data;
      })
      .catch((error) => {
          this.showAlert = true
          this.alertMessage = "An error occurred while downloading the example CSV file. Please try again."
          this.responseStatusCode = "error"
      })
      .finally(() => {
        this.downloadCSV(listFields.fields)
      })
    },

    downloadCSV(fields) {
      let exampleRow = []

      fields.forEach(field => {
        exampleRow.push(field + ' 01')
      })

      const allRows = [fields, exampleRow]
      const csvContent = "data:text/csv;charset=utf-8," + allRows.map(row => row.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement("a")

      link.setAttribute("href", encodedUri)
      link.setAttribute("download", "example.csv")
      document.body.appendChild(link)
      link.click()

      this.showAlert = true
      this.alertMessage = "The example CSV file has been downloaded."
      this.responseStatusCode = "success"
    },

    pushBathDetails(pushBatchId) {
      this.showPushBathDetails = true
      this.showImportContainer = false
      this.view_secndary_info = false
      this.showPushBatchForm = false
      this.showAlert = false
      this.alertMessage = ""

      this.loading = true

      let payload = {
        push_batch_id: pushBatchId
      }

      http.post('/api/v1/dashboard/list-push-line-by-batch/', payload)
      .then((response) => {
        this.pushLines = response.data
      })
      .catch((error) => {
      })
      .finally(() => {
        this.loading = false
      }) 
    },

    returnToImportContainer() {
      this.showPushBathDetails = false
      this.loading = false

      this.showImportContainer = true
      this.view_secndary_info = true
    },


    newStatus(enrollment_id, new_status, old_status, deadlineChangeStatus){
      this.enrollment_id = enrollment_id
      this.new_status = new_status
      this.old_status = old_status
      this.old_deadline = deadlineChangeStatus ? this.formatDate(deadlineChangeStatus) : "No deadline"
    },
    clearStatus(){
      this.enrollment_id = null
      this.new_status = null
      this.deadline = null
    },

    async changeStatus(){
      let payload = {
        token_user: this.token_user,
        enrollment_id: this.enrollment_id,
        status: this.new_status,
        deadline: this.deadline ? this.deadline : undefined
      }
       http.post('/api/v1/dashboard/change-status-enrollment/', payload)
       .then((response) => {
         this.showAlertStatus = true
         this.responseStatusCode = response.status
         this.alertMessage = "Enrollment status changed successfully."
       })
       .catch((error) => {
         this.showAlertStatus = true
         this.alertMessage = "Error changing the status of the participant."
         this.responseStatusCode = error.status
       })
       .finally(() => {
         this.enrollment_id = null
         this.new_status = null
         $('#modalChangeEnroll').modal('hide')
         this.loading = true
         this.deadline = null
         setTimeout(() => {
           this.showAlertStatus = false
           this.getAllenrollments()
         }, 3500)
       })
    },

    blockStudent(){
      let payload = {
        token_user: this.token_user,
        enrollment_id: this.enrollment_id,
        blocked_status: this.statusBlock
      }
      http.post('/api/v1/dashboard/block_student/', payload)
      .then((response) => {
        this.showAlertStatus = true
        this.responseStatusCode = response.status
        this.alertMessage = "Enrollment status changed successfully."
      })
      .catch((error) => {
        this.showAlertStatus = true
        this.showAlertStatus = true;
        if (error.response && error.response.status === 422) {
          this.alertMessage = "This student has been blocked for violating the platform's guidelines, please contact support.";
        } else {
          this.alertMessage = "Error changing the status of the participant.";
          this.responseStatusCode = error.response ? error.response.status : 'unknown';
        }
      })
      .finally(() => {
        this.enrollment_id = null
        this.statusBlock = null
        $('#modalBlockStudent').modal('hide')
        this.loading = true
        setTimeout(() => {
          this.showAlertStatus = false
          this.getAllenrollments()
        }, 3500)
      })
    },
    _block(enrollment_id, status){
      this.enrollment_id = enrollment_id
      this.statusBlock = status
    },
  }
}
</script>
<style scoped>

.card_upload{
  background-color: DCDCDC;
}

.form-select:focus-visible{
  font-weight: bold;
  border: 1px solid #d2d6da
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  background-color: white;
  border: 1px solid #d2d6da;
  color: black;
}

.form-select {
  background-position: right 18px center
}

.text-alert{
  color: #FFF;
}

.push-batch-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  gap: 15px;
}

.top-button {
  margin: 0 10px;
}

.custom-input {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding-left: 10px;
}

.custom-input:focus,
.custom-input:active {
    border-color: #6c757d;
}

</style>