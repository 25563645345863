<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>{{ title }}</h6>
          <p class="text-sm mb-0" v-html="description"></p>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div class="table-responsive">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                v-for="(heading, index) of headers"
                :key="index"
                :class="[
                  index === 0 ? 'ps-2' : '',
                  index >= 1 ? 'text-center' : '',
                ]"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 bg-info "
              >
                <div style="color: white">{{ heading }}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(
                {
                  id,
                  title,
                  proccess_status,  
                  total_registrad,
                  count_clicked,
                  count_answered,
                  progress,
                  created,
                  modified,
                },
              ) of values_list"
              :key="id"
            >
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ title }} #{{ id }}</h6>
                  </div>
                </div>
              </td>

              <td class="text-center d-flex justify-content-center align-items-center">
                <div class="badge bg-gradient-success">
                  <span class="text-light text-xs font-weight-bold"
                    >{{ proccess_status }}
                  </span>  
                </div>
              </td>
              <td class="text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ total_registrad }}
                </span>
              </td>

              
              <td class="text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ count_clicked }}
                </span>
              </td>

              <td class="text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ count_answered }}
                </span>
              </td>
             
              <td class="align-middle d-flex justify-content-end">
                <div class="progress-wrapper w-100 mx-auto">
                  <div class="progress-info">
                    <div class="progress-percentage">
                      <span class="text-xs font-weight-bold"
                        >{{ progress.percentage }}%
                      </span>
                    </div>
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      :class="`w-${progress.percentage}  bg-gradient-${progress.color}`"
                      role="progressbar"
                      :aria-valuenow="progress.percentage"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold align-center"
                  >{{ formatDate(created) }}
                </span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ formatDate(modified) }}
                </span>
              </td>
              <td class="align-middle d-flex justify-content-center gap-2">
                <router-link
                  class="btn bg-gradient-info text-w font-weight-bold btn-sm"
                  :to="{ name: 'Pill Registrations', params: { id: id } }"
                >
                  Registrations
                </router-link>
                <router-link
                  class="btn bg-gradient-info text-w font-weight-bold btn-sm"
                  :to="{ name: 'Pill Answers', params: { id: id } }"
                >
                  Answers
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "list-pillow",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    values_list: {
      id: Number,
      type: Array,
      required: true,

      title: String,
      proccess_status: String,
      total_registrad: String,
      count_clicked: String,
      count_answered: String,
      progress: {
        type: Object,
        percentage: Number,
        color: String,
      },
      created: String,
      modified: String,

    },
  },

  methods: {
    formatDate(date, time = true) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }
      if (!time) {
        return `${year}\-${month}\-${day}`;
      }else{
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      }
      
    },
  }
};
</script>
<style>
.btn-sm {
  padding: 0.5rem 0.5rem;
  font-size: 0.40rem;
  line-height: 0.;
  border-radius: 0.1rem;
  margin-left: 1px  !important;
}
</style>