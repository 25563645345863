<template>
  <div class="col-xl-3 col-md-6 mb-4">
    <div class="card">
      <div :class="[
        'card-header',
        'card-header-text',
        'pt-3',
        'pb-1',
        color == 'primary'
          ? 'bg-primary'
          : color == 'success'
          ? 'bg-success'
          : color == 'info'
          ? 'bg-info'
          : color == 'warning'
          ? 'bg-warning'
          : color == 'danger'
          ? 'bg-danger'
          : 'bg-info',
      ]">
        <h4 class="card-title text-xs font-weight-bold text-uppercase text-light">{{ nameCard }}</h4>
      </div>

      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div class="h5 mb-0 font-weight-bold text-dark" align="right">
              {{ amount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["nameCard", "amount", "color"],
};
</script>
<style></style>