import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'



if (process.env.VUE_APP_ENVRION_VAR == 'development') {
  var headers = {
    "Content-Type": "application/json",
    "Authorization": `Token ${process.env.VUE_APP_AUTH_TOKEN_DEV}`
  };
  
  var headers_file = {
    "Content-Type":  "multipart/form-data",
    "Authorization": `Token ${process.env.VUE_APP_AUTH_TOKEN_FILE_DEV}`
  }
  
  var baseURL = process.env.VUE_APP_BASE_URL_DEV;  
  var url_from_app_user = process.env.VUE_APP_URL_FROM_APP_USER_DEV
  
}

if (process.env.VUE_APP_ENVRION_VAR == 'production') {
  var headers = {
    "Content-Type": "application/json",
    "Authorization": `Token ${process.env.VUE_APP_AUTH_TOKEN_PROD}`
  };

  var headers_file = {
    "Content-Type":  "multipart/form-data",
    "Authorization": `Token ${process.env.VUE_APP_AUTH_TOKEN_PROD}`
  }

  var baseURL = process.env.VUE_APP_BASE_URL_PROD;
  var url_from_app_user = process.env.VUE_APP_URL_FROM_APP_USER_PROD
}

if (process.env.VUE_APP_ENVRION_VAR == 'lab') {
  var headers = {
    "Content-Type": "application/json",
    "Authorization": `Token ${process.env.VUE_APP_AUTH_TOKEN_LAB}`
  };
  var headers_file = {
    "Content-Type":  "multipart/form-data",
    "Authorization": `Token ${process.env.VUE_APP_AUTH_TOKEN_LAB}`
  }

  var baseURL = process.env.VUE_APP_BASE_URL_LAB;
  var url_from_app_user = process.env.VUE_APP_URL_FROM_APP_USER_LAB
  
}

var url_dashboard = process.env.VUE_APP_URL_FROM_DASHBOARD_PROD;

var http = axios.create({
  baseURL: baseURL,
  headers: headers,
});

var http_file = axios.create({
  baseURL: baseURL,
  headers: headers_file,
});



export { http, http_file, axios, url_from_app_user, url_dashboard };